.user-info-item{
    margin-bottom: 10px;

    &__status{
        display: inline-block;
        margin-left: 6px;


        &-active{
            padding: 1px 9px;

            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #28C76F;

            background: rgba(40, 199, 111, 0.12);
            border-radius: 4px;
        }
        &-disactive{
            padding: 1px 9px;

            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #c72828;

            background: rgba(199, 40, 40, 0.12);
            border-radius: 4px;
        }
    }
    p{
        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #6E6B7B;
    }
    span{
        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6E6B7B;

        margin-left: 6px;
    }
}

@media (max-width: 1000px) {
    .user-info-item{
        p{
            font-size: 12px;
        }
        span{
            font-size: 12px;
        }
    }

}
