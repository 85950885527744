.time-container {
    margin: 30px 0 30px 0;
    display: flex;
  
}


.time-btn {
    padding: 3px 20px 3px 20px;
    border: 2px solid #7467ef;
    border-radius: 10px;
    color: #857adc;
    font-size: 14px;
    cursor: pointer;
}


.btn-margin-left {
    margin-left: 10px;
}


.btn-active {
   background-color: #857adc;
   color: white;
}
.stats-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.main-container-stat {
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
   

}

.border-gray {
    border: 2px solid #f3f2f5;
}

.border-purple {
    border: 2px solid #7467ef;
 
}

.header-stat {
    color: #848386;
    font-size: 14px;
    font-weight: 800;
}

.text-stat {
    color: #857adc;
    font-size: 14px;
    font-weight: 500;
}
.text-stat-percent {
    color: #aeadae;
    font-size: 14px;
    font-weight: 500;
}