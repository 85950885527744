.profile{
    max-width: 1000px;
    min-height: 317px;
    background: #fff;
    padding: 20px 25px 20px;
    width: 100%;

    margin: auto auto;

    position: relative;

    border-radius: 10px;

    &__title{
        text-align: center;
        font-size: 28px;
        font-family: var(--firstFont);

        margin-bottom: 10px;
    }
    &__avatar{
        width: 100%;
        max-width: 300px;
        height: 300px;
        border-radius: 10px;
        background: #f8f8f8;
        margin: 0 auto;

        position: relative;

        img {
            width: 100%;
            max-width: 300px;
            height: 300px;
            border-radius: 10px;

        }
        input{
            transition: .5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);

            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    &__wrapper{
        width: 100%;
    }
    &__buttons{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
}

.bar {
    margin-top: 30px;
}


@media (max-width: 500px) {
    .profile{
        max-width: 1000px;
        min-width: 300px;


    }
}
