.confirm-modal {
  width: 500px;
  height: 200px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 11;
  transform: translateX(-50%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-heading {
  text-align: center;
}
.btn-container-modal {
  display: flex;
  margin-top: 10px;
}

.timer-section {
  max-width: 1000px;
  min-height: 82px;
  background: #fff;
  border-radius: 20px;

  padding: 22px 30px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  &__timer-block {
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 20px;
      width: 38px;
      height: 38px;
      cursor: pointer;
      &:hover {
        transform: scale(0.95);
      }
      img {
        width: 38px;
        height: 38px;
      }
    }
    &-timer {
      font-family: var(--firstFont);
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;

      color: #5e5873;
    }
  }
  &__payment-block {
    display: flex;
    align-items: center;
    &-title {
      margin-right: 10px;

      font-family: var(--firstFont);
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: right;
      color: #5e5873;
    }
    &-count {
      font-family: var(--firstFont);
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: right;

      color: #7367f0;
    }
  }
}
@media screen and (max-width: 1000px) {
  .timer-section {
    margin-top: 25px;
  }
}
@media screen and (max-width: 600px) {
  .timer-section {
    //max-width: 1000px;
    min-width: 300px;
    min-height: 82px;
    background: #fff;

    padding: 22px 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px;
    margin-top: 25px;

    &__timer-block {
      display: flex;
      align-items: center;
      margin-right: 20px;

      &-icon {
        margin-right: 12px;
        width: 38px;
        height: 38px;
        cursor: pointer;
        &:hover {
          transform: scale(0.95);
        }
        img {
          width: 38px;
          height: 38px;
        }
      }
      &-timer {
        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        color: #5e5873;
      }
    }
    &__payment-block {
      display: flex;
      align-items: center;
      &-title {
        margin-right: 10px;

        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        color: #5e5873;
      }
      &-count {
        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: right;

        color: #7367f0;
      }
    }
  }
}
