.modal-status {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  &__content {
    padding: 20px;
    border-radius: 12px;
    background: #fff;
    min-height: 200px;
    min-width: 100px;
    max-width: 400px;
    margin: 0 10px;
    width: 100%;
    transform: scale(0.5);
    transition: 0.4s all;

    &.active {
      transform: scale(1);
    }

    &__title {
      text-align: center;
      font-family: var(--firstFont);
      color: #000110;
      font-size: 16px;
    }
  }
}

@media (max-width: 500px) {
}
