.loader{
	z-index: 5;
	height: 100vh;
	width: 100vw;
	background: rgba(0,0,0,0.5);
	position: fixed;
	top: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}

.loader__wrap{
	width: 160px;
	height: 160px;
	background: #FAF7F0;
	border-radius: 20px;
	padding: 20px;
	margin: 0 15px;
	transform: scale(0.5);
	transition: 0.4s all;

	display: flex;
	flex-direction: column;
}

.loader__icon{
	fill: #BCCEF8;
}
