@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.container {
  max-width: 1000px;
  box-shadow: 0px 0px 15px 0px #9f9f9f1a;
}

.navigation {
  background-color: white;
  padding: 22px 24px 22px 24px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.table {
}

.thead {
  &__row {
  }

  &__cell {
    font-family: "Montserrat", sans-serif !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.tbody {
  background-color: white;
  &__cell {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #5e5873;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 14px;

      svg {
        cursor: pointer;
      }
    }
  }
}
