



.report-alone-item {
    max-width: 379px;
    min-width: 200px;
    min-height: 38px;

    margin-top: 15px;
    padding: 8px 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #FFFFFF;
    border-radius: 5px;

    border: 1px solid #d8d6de;



    &__name {
        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #5E5873;
        flex: 1;

        text-transform: uppercase;
    }
    &__status {
       margin-right: 15px;
    }

    &__date {
        margin-right: 15px;
     }

    &__icon-share{
        width: 24px;
        height: 24px;
        cursor: pointer;


        svg{
            width: 24px;
            height: 24px;
        }
        &:hover{
            transform: scale(0.95);
        }
    }
    &__icon-edit{
        width: 20px;
        height: 20px;
        cursor: pointer;

        margin-right: 17px;

        svg{
            width: 20px;
            height: 20px;
        }
        &:hover{
            transform: scale(0.95);
        }
    }
}
