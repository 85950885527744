.navbar {
  margin-left: 20px;
  display: flex;
  gap: 55px;

  &_mob {
    flex-direction: column;
    text-align: end;
  }

  &_mob > &__item + &__item {
    margin-top: 20px;
  }

  &_mob &__link {
    font-size: var(--fontSizeSubTitle);
  }

  &__item {
    position: relative;

    a:hover {
      color: rgba(87, 74, 226, 0.8);
    }
  }

  &__link {
    font-family: var(--firstFont);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #5e5873;
    //width: 80px;
    display: flex;
    justify-content: center;
  }

  .active {
    color: #7367f0;
    border-bottom: 1px solid #7367f0;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    min-width: 300px;
    margin-left: 0;
    display: flex;
    justify-content: space-between;

    &__item {
      position: relative;

      a:hover {
        color: rgba(87, 74, 226, 0.8);
      }
    }

    &__link {
      font-family: var(--firstFont);
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #5e5873;
      padding-bottom: 8px;
      max-width: 80px;
      display: flex;
      justify-content: center;
    }

    .active {
      color: #7367f0;

      &::after {
        content: "";
        bottom: 0;

        position: absolute;

        height: 1px;
        max-width: 85px;
        background: #7367f0;
      }
    }
  }
}
