.alert-notification{
	position: fixed;
	top: 40px;
	right: 20px;
	max-width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 10;
}

.alert-notification-item{
	border-radius: 14px;
	backdrop-filter: blur(43px);
	padding: 15px;
	display: flex;
	align-items: center;
	min-width: 280px;
}

.alert-notification-item.success{
	background: radial-gradient(100% 683.39% at 0% 100%, rgba(98, 172, 97, 0.4) 0%, rgba(98, 172, 97, 0) 100%), linear-gradient(156.26deg, rgba(98, 172, 97, 0.2) 89.37%, rgba(98, 172, 97, 0) 147.21%);
}

.alert-notification-item.error{
	background: radial-gradient(100% 683.39% at 0% 100%, rgba(195, 74, 66, 0.4) 0%, rgba(195, 74, 66, 0) 100%), linear-gradient(156.26deg, rgba(195, 74, 66, 0.2) 89.37%, rgba(195, 74, 66, 0.186364) 89.39%, rgba(195, 74, 66, 0) 147.21%);
}

.alert-notification-item.info{
	background: radial-gradient(100% 683.39% at 0% 100%, rgba(45, 139, 165, 0.4) 0%, rgba(45, 139, 165, 0) 100%), linear-gradient(156.26deg, rgba(45, 139, 165, 0.2) 89.37%, rgba(45, 139, 165, 0) 147.21%);
}

.alert-notification-item.warning{
	background: radial-gradient(100% 683.39% at 0% 100%, rgba(250, 158, 31, 0.4) 0%, rgba(250, 158, 31, 0) 100%), linear-gradient(156.26deg, rgba(250, 158, 31, 0.2) 89.37%, rgba(250, 158, 31, 0) 147.21%);
}

.alert-notification-item__wrap-icon{
	margin-right: 15px;
}

.alert-notification-item__wrap-icon > svg {
	width: 30px;
	height: 30px;
	display: block;
}

.alert-notification-item__title{
	font-family: var(--firstFont);
	font-size: var(--fontSizeSubTitle);
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: 0.5px;
}

.alert-notification-item__text{
	font-family: var(--firstFont);
	font-size: var(--fontSizeText);
	font-weight: 400;
	line-height: 1.2;
	letter-spacing: 0.5px;
}