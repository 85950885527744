@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.container {
  min-width: 900px;
  box-shadow: 0px 0px 15px 0px #9f9f9f1a;
}

.navigation {
  background-color: white;
  padding: 22px 24px 22px 24px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.table {
}

.thead {
  &__row {
  }

  &__cell {
    font-family: "Montserrat", sans-serif !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.active {
  background-color: #7367f0;

  p {
    color: white;
  }

  a {
    color: white;
  }
}

.old {
  background-color: #f3f2f7;
}

.lastItem {
  td:first-child {
    border-bottom-left-radius: 20px !important;
  }
  td:last-child {
    border-bottom-right-radius: 20px !important;
  }
}

.tbody {
  background-color: white;

  &__cell {
    padding: 10px;
    &_text {
      display: block;
      max-width: 120px;

      word-wrap: break-word;
      color: #7367f0;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 12px;
    }
    &_heading {
      max-width: 120px;
      padding: 10px;
      word-wrap: break-word;
      color: #5e5873;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 14px;
    }

    svg {
      cursor: pointer;
    }
  }
}

.status {
  max-width: 120px;
  word-wrap: break-word;
  color: #5e5873;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;

  &_green {
    color: #20be00;
  }

  &_red {
    color: #cc5a5a;
  }

  &_blue {
    color: #5179ff;
  }

  &_gray {
    color: #6e6b7b;
  }
}
