.selector{

    width: 100%;
    position: relative;

    &__title{
        margin-bottom: 5px;

        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #6E6B7B;

    }
    button:disabled,
    button[disabled]{
        cursor: revert;
    }

    &-selected{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        background: #fff;
        border: 1px solid #d8d6de;
        border-radius: 5px;
        height: 38px;
        cursor: pointer;

        width: 100%;

        &-error{
            border: 1px solid #ff0000;
        }
 &-disabled {
 
        background-color: #ddd;
    
    
    
 }

        &-item{
            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;

            color: #0c0c0c;
            &-null{
                font-family: var(--firstFont);
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
                color: #B9B9C3;
            }
        }
        &-icon{
            width: 20px;
            height: 20px;

            svg{
                width: 20px;
                height: 20px;
            }
        }
    }

    &__popup-menu{
        position: absolute;
        top: 65px;
        left: 0;
        z-index: 6;

        width: 100%;
        min-height: 30px;
        background: #ffffff;
        padding: 10px;
        border-radius: 10px;

        border: 1px solid #d8d6de;

        margin-top: -3px;

        li{
            margin-top: 3px;
            cursor: pointer;

            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #050505;
            &:hover{
                color: #1a0bfa;
            }
        }
    }
}

.opened-popup{
    transform: rotate(180deg)
}
