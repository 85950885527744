.global-error {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    height: 110%;



    &__wrapper {
        min-width: 200px;
        max-width: 500px;
        //width: 200px;
        height: 200px;
        background: rgba(229, 39, 39, 0.5);
        border-radius: 20px;
        padding: 20px;
        text-align: center;


        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 20px;
        font-family: var(--firstFont);
		color: #FFFEFE;
    }
}
.success-mes{
    background: rgba(12, 226, 5, 0.5);
}

@media (max-width: 500px){
	.global-error {


		&__wrapper {
			max-width: 300px;
		}
	}
}
