* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  color: inherit;
}

.main-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.main-profile {
  margin-right: 20px;
  flex: 3;
  min-width: 340px;
}
.main-mob-profile {
  display: none;
  min-width: 340px;
}

.main-info {
  min-width: 340px;
  flex: 6;

  &__timer {
    margin-bottom: 24px;
    min-width: 340px;
  }

  &__outlet {
    min-width: 340px;
  }
}

@media (max-width: 1000px) {
  .main-wrapper {
    flex-direction: column;
    min-width: 330px;
  }
  .main-profile {
    display: none;
  }
  .main-info {
    flex: 1;
  }

  .main-mob-profile {
    display: block;
    flex: 1;
  }
}
